import React from 'react'
import { Box, Card, Chip } from '@mui/material'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { getEventBot } from 'service/eventApi'
import { Bot } from 'client'

interface Props {
  bot?: Bot
}

export default function Attendees({ bot }: Props) {

  if (bot?.meeting_participants && bot?.meeting_participants.length > 0)
    return (
      <Card>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {bot?.meeting_participants.map(p => {
            const name = p.name + '  ' + (p.is_host ? '(host)' : '')
            return (
              <Box key={p.id} >
                <Chip label={name} />
              </Box>
            )
          })
          }
        </Box>
      </Card >
    )
  return null
}