import React, { useEffect, useState } from 'react'
import {
  Box,
  SxProps,
  Card,
  Typography,
  IconButton,
  Skeleton,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { ClientDetail, EventBase } from '../../client'
import { gSx } from '../../styles/Theme'
import ClientEventDialog from './ClientCardForEventDialog'
import { ContactChip } from './ContactChip'

interface Props {
  event?: EventBase
  disabled?: boolean
}

export default function ClientCardForEvent({ event, disabled }: Props) {
  const [client, setClient] = useState<ClientDetail | undefined>(event?.client)
  const [showDialog, setDialog] = useState(false)
  const isProcessing = (!event || !event.owner_id)

  useEffect(() => {
    setClient(event?.client)
  }, [event])

  return (
    <Card>
      {
        isProcessing ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Skeleton variant="rectangular" width={'80%'} height={20} />
            <Skeleton variant="rectangular" width={'80%'} height={20} />
          </Box>
        ) : (
          <>
            <Box sx={gSx.RowBetween}>
              <ContactChip contact={client} />

              <Box sx={{ padding: 1 }} />
              <IconButton
                aria-label='edit'
                onClick={() => setDialog(true)}
                disabled={disabled}
              >
                <EditIcon fontSize='small' />
              </IconButton>
            </Box>
          </>
        )
      }

      <ClientEventDialog
        event={event}
        open={showDialog}
        onClose={() => setDialog(false)}
      />

    </Card>
  )
}

const LeftColumn: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
}
